@import url('https://fonts.cdnfonts.com/css/verdana');


.eperfile{
    width: 72% !important;
    margin-left: 27% !important;
}

.contprofile{
    /* flex-wrap: nowrap; error run build */
    display: flex;
 
    
}

.rowp {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    /* flex-wrap: wrap; */
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}   

.imageperfil{
    width: 70%;
}

.col-md-3-1 {
    flex: 0 0 auto;
    width: 26%;
    margin-right: 0.7%;
}

.cardprofile {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    /* border: 1px solid rgba(0, 0, 0, 0.125); */
    border-radius: 0.25rem;
}


.p-btn-grabar {
    color: #fff;
    background-color: #68d1d1;
    border-color: #68d1d1;
    text-transform: uppercase;
    font-weight: bold;
    padding: 14px 42px;
    border-radius: 0.2rem;
    border: none;
    margin-left: -1%;
    margin-top: 1%;
}

.p-btn-grabar:hover{
    color: #fff;
    background-color: #31B8BD;
    border-color: #31B8BD;
}


.btn-cancel {
    color: #fff;
    background-color: #b1b4b3;
    border-color: #b1b4b3;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 0.2rem;
    border: none;
    padding: 14px 31px;
    margin-top: 1%;
}

.btn-cancel:hover{
    color: #fff;
    background-color: #8f8f8f;
    border-color: #8f8f8f;
}

.btn-grabar {
    color: #fff;
    background-color: #68d1d1;
    border-color: #68d1d1;
    text-transform: uppercase;
    font-weight: bold;
    padding: 14px 45px;
}

.btn-grabar:hover{
    color: #fff;
    background-color: #31B8BD;
    border-color: #31B8BD;
}
.btn-plus1 {
    margin-right: 1rem;
    color: #fff;
    background-color: #8039B0;
    border-color: #8039B0;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 0.2rem;
    border: none;
}


.btn-dangerT {
    color: #fff;
    background-color: #8039B0;
    border-color: #8039B0;
}
.btn-dangerT :hover{
    color: #fff;
}

.bd-wizard-step-title1{
    border: none;
    background-color: #ffffff00;
    text-align: center;
    line-height: 1;
    font-size: 15px;
    font-weight: bold;
    color: #000000;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important;
    font-size: 13px;
}
.contp {
    width: 75%;
    /* padding: 20px 10px 10px 0px; */

    margin: 0 auto;
}


.a1p {
    width: 100%;
    background-color: rgb(90 90 90 / 10%);
}

.textosti{
    color: #8039B0;
    font-size: 20px;
    font-family: 'Verdana', sans-serif;
    margin-left: -2%;
}


.form-group-dni{
    width: 24%;
    margin-right:4%;
    margin-bottom: 5%;
}

.form-group-alias{
    width: 25%;
    margin-bottom: 5%;
}
.form-group-nombres{
    width: 44%;
    margin-right:4%;
    margin-bottom: 5%;
}


.pmb-3 {
    margin-bottom: 1rem !important;
    display: block;
}

.selector{
    margin-right: 10%;
}
.selector1{
    margin-right: 7%;
}

@media only screen
and (min-width: 0px) and (max-width: 750px) {


.contprofile {
    /* flex-wrap: nowrap;    error run build */
    display: block;
        width: 100% !important;
        margin-left: 0% !important;
    
}


.col-md-3-1 {
    width: auto; 
    margin-right: 0%;
}
.rowp {
    display: block;
    /* flex-wrap: wrap; */
}

.form-group-dni {
    width: auto;
    margin-right: 0%;

}
.form-group-nombres {
    width: auto;
    margin-right: 0%;

}
.form-group-alias {
    width: auto;
    margin-bottom: 5%;
}
label{
    font-size: 15px;
}

.cel{
    font-size: 15px;
}

.media-body0{
    display: none;
}

.contp {
    width: 86%;
    /* padding: 20px 10px 10px 0px; */

}

}



@media only screen
  and (min-width: 751px) and (max-width: 1000px){}

  @media only 
  screen and (min-width: 1500px) and (max-width:1920px){
.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important;
    font-size: 17px;
}

}