body {
    font-family: 'Karla', sans-serif;
    background-color: #f2f3f8;
}


/* Wizard */

.moper{
    width: 40% !important;
    margin-left: 49.3% !important;
}

.pasos{
    font-size: 10px !important;
}


.containerbox{    
    width: 70% !important;
    height: 720px;    
    margin: 1% auto 6%;

}
.containerreg {
    width: 70% !important;
    margin: 0 auto;
}
.contentreg{
    padding: 30px 35px 118px 35px;
    
    background-color: #fff;
    pointer-events: auto;
}

.wizard {

    a:hover {
        text-decoration: none;
    }

    .audible {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }

    .steps {

        &>ul {
            list-style: none;
      
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;

            li {
                width: 270px;
                max-width: calc(21% - 6px);

                a {
                        display: inline-block;
                        text-decoration: none !important;

                        height: 100%;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        background-color: rgba(153, 155, 163, 0.1);
                        padding: 28px 30px 24px;
                    

                    @media (max-width: 767px) {
                        padding: 15px 10px;
                    }

                    .media {
                        display: flex;
                        @media (max-width: 767px) {
                            display: block;
                        }
                    }
                }

              

                .bd-wizard-step-icon {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
               
                    font-size: 14px;
                    line-height: 34px;
                    text-align: center;

                    
                    margin-right: 11px;

                    // @media (max-width: 970px) {
                    //     margin-left: -88%;
                    // }
                }

                .cls-1-1 {
                    fill: #b1b4b3;
                }

                .cls-3-m3-1,.cls-2-m3-2, .cls-1-m3-3 {
                    fill: #b1b4b3;
                }
                .cls-1-m3-1, .cls-1-m3-2, .cls-2-m3-3{
                    fill: #fdfdfd;
                }

                .cls-1-m3-1:hover{
                    fill: #68d1d1;
                 }
                 

               
             
                .bd-wizard-step-title {
                    line-height: 1;
                    font-size: 15px;
                    font-weight: bold;
                    color: #000000;

                    @media (max-width: 970px) {
                        display: none;
                    }
                }
        
                .bd-wizard-step-subtitle {
                    line-height: 1;
            
                    color: #c8c8c8;

                    @media (max-width: 970px) {
                        display: none;
                    }
                }

                &.current {
                    .cls-1-1 {
                        fill: #803cb0;
                    }
                    
                    .cls-2-1{
                        fill:#68d1d1
                    }

                    .cls-3-m3-1{
                        fill: #803cb0;
                    }
                    .cls-1-m3-1{
                        fill: #68d1d1;
                    }
                    
                }

          

                &.current {
                    a {
                        background-color: #fff;


                    }
                }
            }

          

       


            
        }
    }

    .content {
        width: 100%;
        background-color: #fff;
       
    }

  

    .actions {
        padding: 0 35px 35px;
        background-color: rgba(255, 255, 255, 0);
        display: flex;
        justify-content: flex-end;
        margin-top: -13%;
        
        .btnStep {
            border-radius: 6px;
            border: none;
            margin-left: 1rem;
            background-color: #68d1d1;
            padding: 0px 18px;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            height: 43px;
        }
         .btnStep:hover{
            background-color:#00bdbd;
        }

        .btnCancelar {
            border-radius: 6px;
            border: none;
            margin-left: 1rem;
            background-color: #b1b4b3;
            padding: 1px 22px;
            color: #fff;
            font-style: 12px !important;
            font-weight: bold;
            height: 43px;
        }
        .btnCancelar:hover{
            background-color: #5a5a5a;
        }

        .btnSave {
            border-radius: 6px;
            border: none;
            margin-left: 1rem;
            background-color: #62e22f;
            padding: 16px 30px;
            color: #fff;
            font-style: 15px;
            font-weight: bold;
        }

        @media (max-width: 767px) {
            padding-left: 20px;
            padding-right: 20px;
            display: block;

            .btnStep{
                width: 100%;
                margin: 5% 0;
            }
            .btnCancelar{
                width: 100%;
                margin: 0;
            }
        }

        &>ul {
            list-style: none;
            padding-left: 0;
            display: flex;
            justify-content: flex-end;
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 15px;
            border-bottom: 1px solid #f5f5f4;
        }

        li {
            a {
                display: inline-block;
                text-decoration: none !important;
                border-radius: 6px;
                background-color: #00d69f;
                padding: 16px 30px;
                color: #fff;
                font-style: 15px;
                font-weight: bold;
            }

            &.disabled {
                display: none;
            }

            &:not(.disabled)+li,
            &:not(:first-child):last-child {
                margin-left: 15px;
            }
        }
    }

    .contbnts{
        margin-top: -18%;
        margin-right: 3%;
    }
    
}

.messageConfirm {
    background-color: rgba(214, 212, 212, 0.2)!important;
}


.containertotal{
    width: 60%;
    margin: 0 auto;
}

.container{
    width: 70% !important ;
    margin: 0 auto;
}

.a1{
    width: 135%;
}

.a1:hover{
    background-color:rgb(255, 255, 255)!important;

}



.col-md-3 {
    flex: 0 0 auto;
    width: 30%;
    margin-right: 3%;
}

.cntbancos{
    width: 31%;
    margin-right: -1%;
}

.aliasctn{
    width: 25%;
    margin-right: 0%;
}
.cold-md-3 {
    flex: 0 0 auto;
    width: 26%;
    margin-right: 0.7%;
}


.col-md-31 {
    flex: 0 0 auto;
    // width: 30%;
}
.col-md-9 {
    flex: 0 0 auto;
    width: 65%;
}

.col-md-91 {
    flex: 0 0 auto;
    width: 65%;
}
.col-md-9-1 {
    flex: 0 0 auto;
    width: 75%;

}

.containerEmp {
    width: 70%;
}
.containerTemp {
    // width: 80%;
    margin: 0 auto;
}


.imageidentidad{
    margin-top: 10%;
    width: 80%;
}

.casiterminamos{
    width: 80%;
    margin-top: 10%;
}
.containerhistory {
     width: 100%;
}

.txthistory{
    width: 22%;
    margin-right: 3%;
}

.contf1{
    width: 94%;
    margin-left: -2%;
}

.continfo{
    width: 74.5%;
    margin-left: -5%;
}
.modal-dialog {
    /* max-width: 500px; */
    margin: 1.75rem auto !important;
    background-color: #fff;
    pointer-events: auto !important;
}


@media only screen
and (min-width: 0px) and (max-width: 750px) {

    .containerreg {
        width: 90% !important;
        margin: 0 auto;
    }
    .container {
        width: 100% !important;
        margin: 3% auto !important;
    }
    .moper {
        width: 77% !important;
        margin-left: 7.3% !important;
    }

    .col-md-3{
        display: none;
    }
    .aliasctn {
        width: auto;
        margin-right: 0%;
    }

    .containertotal{
        width: 100%;
    }
    .col-md-3 {
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 5%;
    }
    
    .col-md-31 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-md-9 {
        flex: 0 0 auto;
        width: 100%;
    }
    .contf1 {
        width: auto;
        margin-left: 0%
    }


    .col-md-9-1 {
        flex: 0 0 auto;
        width: 100%;
    }

    .col-md-91 {
        flex: 0 0 auto;
        width: 100%;
    }

    .continfo {
        width: auto;
        margin-left: 0%;
    }

    .casiterminamos{
        display: none;
    }

 
  
}


@media only screen
  and (min-width: 751px) and (max-width: 1400px){

  }

 @media only 
	screen and (min-width: 1500px) and (max-width:1920px){

     .contentreg{
        padding: 30px 35px 140px 35px;
        
        background-color: #fff;
    }
    }

