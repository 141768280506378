.seccion-contenedor{
       background-size: 100%;
    background-image: url("../img/plataforma segura para cambiar dólares.png");
    height: 220px;
    margin-top: 0%;

}

/* .imgseccion{
    position: relative;
    width: 100%;
    height: 80%;
} */


.txt1-s {
    color: #ffffff;
    font-size: 24px;
    width: 30%;
    margin-left: 10%;
    margin-top: 3%;
    line-height : 29px;
    }

.txt2-s {
    color: #ffffff;
    font-size: 24px;

    margin-left: 10%;

    font-weight: 600;
    }

.txt3-s {
    width: 15%;
    color: #000000;
    font-size: 20x;
    margin-left: 75%;
    margin-top: -7%;
    margin-bottom: 10%;
    }


    @media only screen
    and (min-width: 0px) and (max-width: 750px){

    .txt1-s {
        color: white;
        font-size: 9px;
        width: 34%;
        margin-left: 10%;
        margin-top: 0%;
        line-height: 13px;
    }

    .txt2-s {
        font-size: 10px;
        color: white;}

        .txt3-s {
            color: #ffffff;
            font-size: 18x;
            margin-left: 70%;
            margin-top: -9%;
            margin-bottom: 10%;
        }

        .seccion-contenedor {
            background-size: 135%;
            height: 98px;
        }

}

@media only screen
  and (min-width: 751px) and (max-width: 1000px){}

 @media only 
	screen and (min-width: 1500px) and (max-width:2500px){

        .seccion-contenedor {
            background-size: 100%;
            height: 311px !important;
            margin-top: 0%;
        }
    }