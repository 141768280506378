

.contpoliticas{
    text-align: justify;
    color: rgb(100, 100, 100);
}


.txtpolitica{
text-align: center;
color: #8039B0 ;
}