.btn-secondaryEmp {

    color: #fff !important ; 
    border-radius: 6px !important;
    border: none !important;
    margin-left: 1rem !important;
    background-color: #b1b4b3 !important;
    padding: 12px 3% !important;
    font-size: 15px !important;
    text-transform: uppercase !important;
    font-weight: bold !important;


}

.contreloj{
    display: none;
}
.btn-secondaryEmp:hover{
    color: #fff;
    background-color: #8f8f8f;
    border-color: #8f8f8f;
}

.btn-dangerEmp {
    color: #fff !important;
    border-radius: 6px !important;
    border: none !important;
    margin-left: 1rem;
    background-color: #68d1d1 !important;
    padding: 12px 5% !important;
    font-size: 15px  !important;
    text-transform: uppercase !important;;
    font-weight: bold !important;;
}
.btn-dangerEmp:hover{
    color: #fff;
    background-color: #31B8BD;
    border-color: #31B8BD;
}
.elementotitulo {
    color: #68d1d1;
    font-family: "Keep Calm Med";
    font-size: 25px;
    line-height: 1;
    width: 100%;
    margin-bottom: 5%;
}


.contentEmp  {
    font-family: 'Verdana', sans-serif;
    width: 90%;
    font-size: 16px;
}
.imagecntemp{
    width: auto;
    margin-top:10%;
    margin-left: -12%;
}
.registeremp{
    flex-wrap: inherit !important;
}


@media only screen
and (min-width: 0px) and (max-width: 750px)
{
    .contreloj {
        color: red;
        margin: 0 auto;
        display: block;
    }
.imageperfil{
    visibility: hidden;
    width: 0%;
}

.imagecntemp{
    visibility: hidden;
    width: 0%;
}

.col-md-3 {
    width: auto;
    font-size: 16px;
    text-align: center;
}

}


@media only screen
and (min-width: 751px) and (max-width: 1000px){
    .contreloj {
        color: red;
        display: none;
    }  
}

@media only 
  screen and (min-width: 1500px) and (max-width:1920px){
.contreloj {
    color: red;
    display: none;
}
  }