@import url('https://fonts.cdnfonts.com/css/verdana');

/* CUADRO BLANCO */

.caja1-tipo-cambio { 

    padding: 0px 0px;
    height: 50px;
    width: 90%;
    border-radius: 10px;
    border:2px solid #ced4da;;
    font-size: 10px;
    height: 50px;
    align-items: center !important;
    margin-left: 10px;
    display: flex !important; 

}
.cajapen{
    display: inline-block;
    width: 20%;
    font-size: 13px;
    padding: 4px;
}

.hropera{
    margin-left: 13%;
}

.total{
    width: 90%;
}

.imput-tipo-cambio1{
    border: 0px;
    background: transparent;
     width: 100%; 
     touch-action: manipulation;
     outline: none;
     padding: 0.778rem 0.75rem;
     height: 10px;
     font-size: 28px;
}


.precio1{
    float: left;
    /* border: steelblue solid 1px; */
    padding: 1px;
}
.precio2{
    float: left;
    /* border: steelblue solid 1px; */
    margin-left: 1%;
    padding: 1px;
}

.texto1{
    color: #5c5b5b;
    font-size: 15px;
    text-align: center;
    font-weight: 400;
}

.textow{
    color: #000000;
    font-size: 25px;
    text-align: center;
     font-weight: bold;
}


/* BOTON */

.text-centerq {
    margin-bottom: -52px;
    margin-top: -20px;
    margin-left: 70%;
}

.flechas-cambiar-moneda1 {
    /* position: fixed; */
  
    line-height: 55px;
    background: #7c40d5;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 39px;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 30%);
    z-index: 100;
    cursor: pointer;
}



/* .conteniner{
   width: 50%;
} */
.contenedor-tipo-cambio {
    display: block;
    width: 55%;
}

.operacion{
    font-size: 13px;
    color: inherit;
    width: 100%;
    padding: 5% 0%;
}


.cajamayor{
    
    padding: 10% 0%;
}

.contenedor-convertidor1{
    display: flex;
    justify-content: center;
    border: 2px;
 
}

.imput-tipo-cambio {
    border: 0;
    background: transparent;
    width: 65%;
    margin-left: 20px;
}

.btn-danger1 {
    margin-top: 10px;
    border-radius: 6px;
    border: none;
    margin-left: 1rem;
    margin-bottom: 10px;
    background-color: #8039B0;
    padding: 12px 15px;
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    float: left;
}

.btn-danger1:hover {
    background-color: rgb(88, 53, 204);
  }

.form-check1{
    margin-top: 8px;
    margin-bottom: 3px;
    color: #8039B0;
    width: 280px;
}

.form-check-label1{
    margin-left: 5px;
}


.form-control1
{
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    padding: 0px 0px;
    height: 50px;
    width: 80%;
    border-radius: 10px;
    border: 2px solid #ced4da;
    font-size: 15px;
    margin-bottom: 5px;
    margin-left: 30px;
}

/* .row12 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
} */

/* 
.container15{
    width: 90%;
}
 */
/* todo cuerpo*/
.card1 {
    position: relative;
    line-height: 1.5;
    display: flex;
    /* flex-direction: column;
    min-width: 0;
    word-wrap: break-word; */
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    /* border: 1px solid rgba(255, 0, 0, 0.781); */
    border-radius: 0.25rem;
    padding: 1rem;
    width: 55%;
    margin:0px auto;
  }
  
  
  .col-lg-31 {
    flex: 0 0 auto;
    /* width: 25%; */
  }
   
  .mt-21{
    background-color: rgb(255, 255, 255);
    font-family: 'Verdana', sans-serif;
    /* line-height: 2; */
    width: auto ;
    /* border: 1px solid rgba(255, 0, 0, 0.781); */
    margin: 10px 20%;
    margin:0px auto;
  }

  .card-body1 {
    display: flex;
    align-content: center;    
    margin:0px auto ;
    padding: 0rem;
    float: center;
    /* width: 55%; */
  }

  .card-body2 {
    background-color: rgb(255, 255, 255);
    display: flex;
    padding: 0rem;    
    margin: 0 auto;
    width: 90%;
  }

  
.titulooperation{
    color: #68d1d1;
    font-family: "Keep Calm Med";
    font-size: 30px;
    line-height: 1;
    margin-left: 10px;
    /* width: 35%; */
  }

  .p-41 {
    background-color: #ffffff;
    width: 100%;
    border: none;
    display: flex;
    margin: 0 auto;
    padding: 3%;
}
.p-42 {
    background-color: #ffffff;
    width: 60%;
    border: none;
    display: flex;
    margin: 0 auto;
    padding: 3%;
}

.p-43 {
    background-color: #ffffff;
    width: 91%;
    border: none;
    display: flex;
    margin: 0 auto;
    padding: 3%;
}
  
/* MODAL */

.contenedormodal1{
    background-color: #0000c4;
    width: 40%;
    align-items: center;
    border: none;
    margin: 0 auto;
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: center;
}

.txtmo1{
    color: #fff;
    font-size: 20px !important;
    margin: 0 auto;
}
.txtmo2{
    color: #fff;
    font-size: 16px !important;
    margin: 0 auto;
}

.txtmotitulo {
    color: #68d1d1;
    font-family: "Keep Calm Med";
    font-size: 25px;
    line-height: 1;
    width: 100%;
}

.txtm2{
    display: flex;
    margin: 0 auto;
}

.txt-t1{
    color: #68d1d1;
    font-size: 18px;
}

.txtm21{
    width: 20%;
    margin-right: 15%;
    margin-left: 15%;
}

.txtm22{
    width: 20%;
}


.txt-t2{
    color: #ffffff;
    font-size: 22px;
    margin-top: -37%;
    text-align: center;
}

.txt-t3{
    color: #ffffff;
    font-size: 22px;
    margin-top: -37%;
    text-align: center;
}

.vl-cvmodal {
    border-left: 2px solid #68D1D1;
      height: 70px;
      margin-right: 5%;
    }

.txtmo3{
    color: #ffffff;
    font-size: 14px;

}

/* FIN MODAL */

.contenedorreloj1{
    display: flex;
}

.imgoperaP1{
    width: 35%;
    margin-left: 25%;
}

.contclock{
    background-color: #ffffff;
    width: 26%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    margin-right: 5%;
}


.col-md-7 {
    flex: 0 0 auto ;
    width: 66% !important;
    margin-top: 0% !important;
  
}
.text-whites{
 color: rgb(0, 0, 0);
}

/* .rowCont {
   color: #fff;
   width: 80%;
} */

#cardOperation {
    background-color: #ffffff;
    width: 45%;
    margin:0px auto
}

.w-100 {
    width: 50% !important;
}
.p1txt1{
    color: #000000;
    font-size: 12px;
    text-align: justify;
}

.div1 {
    padding: 20px 0;
    overflow: hidden;
}
.mt-110 {
    border-radius: 6px;
    border: none;
    /* margin-left: 5%; */
    background-color: #8039B0;
    padding: 3%;
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    width: 45%;
    font-weight: bold;
    padding: 12px 15px;
    margin-top: 23px;

}

.mt-110:hover{
    background-color: #6900af;
  }

.col-md-33 {
    width: 35%;
    /* padding: 0% 5%; */
    /* margin-left: -40%; */
    margin-right: 8%;
}

.wt{
    width: 50%;
    margin: 0 auto;
}

.wizard .content .p1 {
    color: #030303;
    font-family: 'Verdana', sans-serif;
    width: 90%;
    font-size: 13px;
}

.wizard .content p {
    font-family: 'Verdana', sans-serif;
    width: 95%;
    
}

.continfof{
    margin-left: 5%;
}

.fintercambio:hover {
    /* background-color: yellow; */
    /* box-shadow: 10px -16px teal; */
    cursor:pointer;
  }


.fintercambio {
    margin-top: -3%;
    margin-bottom: 0%;
    width: 50%;
}
.operaCambio{
 
    margin: 0 auto;
}

.operaElige{
    /* width: 90%;
    margin: 0 auto; */
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    
}

.imgopera{
    width: 70%;
    margin-top: 10%;
    margin-left: 15%;
}

@media only screen
and (min-width: 0px) and (max-width: 750px)
{
    .operaCambio{
        width: 100%;
        margin: 0 auto;
    }

    .contenedormodal1 {
        background-color: #0000c4;
        width: 80%;
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 2%;
    }
.p-41 {
    display: block;
}

.fintercambio {
    margin-top: -35%;
    margin-bottom: -23%;
    width: 71%;
}


.contenedor-tipo-cambio{
    margin-left: 0%;
}

.col-md-33 {
    width: 100%;
}

.elementotitulo{
    text-align: center;
}
.elementop1{
    text-align: center;
}
.conteniner{
    padding: 0 0px 0 0px;
    margin-top: -5%;
 } 

.p1, p{
    /* width: 100%; */
    text-align: center;
    text-align: justify;
}

.contenedor-tipo-cambio {
    width: 100%;
    justify-content: center;
}

.texto1{
    font-size: 13px;
}

.form-check-label1 {
    margin-left: 5px;
    width: 85%;
}
/* 
.textow{
    font-size: 13px;
} */


.operacion {
    font-size: 13px;
    color: inherit;
 
 margin-top: 5%;
}

.contenedor-convertidor1 {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    /* border: 2px; */
    width: 100%;
    }

.precio2 {
    float: left;
    /* border: steelblue solid 1px; */
    margin-left: 10%;
    padding: 1px;    

}

.mt-110 {
     /* margin-left: 10%; */
     width: 90%;
     justify-content: center;
}


#page{
    width: 100%;
}

.imput-tipo-cambio1{
    font-size: 20px;
}
.cajapen {
    font-size: 10px;   
}

.cajapen{
    width: 30%;
}

.wizard .content p{
    text-align: center;
}
.imgopera{
    visibility: hidden;
    width: 0%;}


    .operacion {
        padding: 12% 0%;
    }

.wizard .content .p1 {
width: auto;
}

.p-42, .p-43 {
    display: block;
    width: 85%;
}

.contenedorreloj1 {
    display: block;
}

.contclock {
    display: none;
}

.imgoperaP1 {
    display: none;
}

}

@media only 
screen and (min-width: 1500px) and (max-width:1920px)
{


.imput-tipo-cambio1 {
    border: 0px;
    background: transparent;
    width: 100%;
    touch-action: manipulation;
    outline: none;
    padding: 0.778rem 0.75rem;
    height: 31px;
    font-size: 36px;
}

.operacion {
    font-size: 20px;
    color: inherit;
    width: 100%;
    padding: 8% 0%;
}
.cajapen {
    display: inline-block;
    width: 20%;
    font-size: 18px;
    padding: 4px;
}

.caja1-tipo-cambio {
    padding: 0px 0px;
    height: 50px;
    width: 90%;
    border-radius: 10px;
    border: 2px solid #ced4da;
    font-size: 10px;
    height: 60px;
    align-items: center !important;
    margin-left: 10px;
    display: flex !important;
}
}

