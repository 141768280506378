@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
  background-color: white;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter', sans-serif;
  display: grid;
}

.container {
  width: 100%;
	padding-right:15px;
	padding-left:15px;
	margin-right:auto;
	margin-left:auto
}

img {
  max-width: 100%;
  height: auto;
}

.imgperfil{
 width: 10% !important; 
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 10px;
}

.portlogo{
padding-top: 15%;
margin-left: 50% !important;
  width: 100%;
} 


.is-active {
  color: #07a8ff !important;

}

.navBar .col-md-3 {
  z-index: 7;
}

.btn-info {
  color: rgb(255, 255, 255)!important;
  background-color: #8039B0 !important;
  border-color: #8039B0;
  border-radius: 7px;
}



.btn-info1 {
  color: #000;
  border-color: #ffffff;
   border-width: 1px;
   border-style: solid;
   border-radius: 7px;
}

.header_login{
  display: none;
}

ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 5% -50%;
  padding: 0;
  float: right;
  z-index: 7;

  width: 100%;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-right: 10px;
}
.menu-item {
  line-height: 14px;
  margin-right: 2%;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-weight: 500;
  position: relative;
  display: block;
  color: #ffffff;
  margin: 17px 17px;
  text-decoration: none;
  cursor: pointer;
  line-height: 25px;
}

.menu-item-login {
  text-align: center;
  display: flex;
}

.menu-item-login a span {
  font-size: 0.83em;
  margin-top: -5px; 
  position: absolute;

}
.menuname{
  top:-40%
}

.header__middle__logo{
  width: 10%;
  display: inline-block;
  float: left;
  margin-left: -6%;
  margin-right: 6%;
}
.header__middle__menus {
  width: 85%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  float: left;
  border-bottom: 2px solid #ffffff;
  position: relative;
  box-shadow: 0 5px 42px 0 rgb(42 46 54 / 40%);
  background: -webkit-linear-gradient(180deg, #8039B0 5% , #68D1D1 95%);
 
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #700692;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 5px;
  margin-left: 4.7rem;
}
.sub__menus {
  margin-top: 15%;
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
  color: #000;
}

.btnnop {
  border-radius: 6px;
  border: none;
  /* margin-left: 5%; */
  background-color: #8039B0;
  padding: 3%;
  color: rgb(255, 255, 255) !important;
  
  font-size: 15px;
  text-transform: uppercase;
  /* width: 45%; */
  font-weight: bold;
  padding: 0px 2px;
}
.btnnop:hover{
  background-color: #6900af;
  color: rgb(255, 255, 255) !important;
}

@media (max-width:767px) { 
  .header__middle .active {
    color: #700692 !important;
  }
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
  color: #000000;
}
.menu-item:hover > a{
  color: #07a8ff !important;
}
.menu-item .sub__menus li:hover a{
  color: #000000 !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 10px 0 0 0;
}

@media only screen
and (min-width: 0px) and (max-width: 690px){

.header__middle {
  display: flex;
  width: 100%;
  float: left;
  border-bottom: 2px solid #ffffff;
  position: relative;
  box-shadow: 0 5px 42px 0 rgb(42 46 54 / 40%);
  background: -webkit-linear-gradient(180deg, #8039B0 5% , #68D1D1 95%);
  height: 100px;
}
}

@media(max-width:991px){
.header__middle__logo {width: 20%;}
.header__middle__menus {width: 20%; float: right;}
.header__middle__logo img {    max-width: 97px;
  margin: 0px -121px 26px -21px;
}
.main-nav .menubar__button:hover {background-color: #700692;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 27px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}

  .main-nav ul.menuq2 {
    display: block!important;
    position: absolute;
    left: 0;
    z-index: 9927;
    background-color: #d8f1ff;
    border-bottom: 3px solid #700692;
    /* right: -1px; */
    padding-bottom: 17px;
    /* width: 50%; */
    padding: 5%;
    top: 46%;
}
.main-nav .menu-item a {
  /* margin: 10px 17px; */
  text-align: center;color: #000000;
  border-bottom: solid 1px;
  padding: 1rem;
}
.main-nav .menu-item .btnnop a {
  border: 0px !important;
}

.main-nav  ul.main-menu {display: none}
.sub__menus {width: 80%;}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}
.menu-item-login { margin-top: 0px; font-size: 0.95em;}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{margin-left: 6rem;}
.menusubitem{
  border-bottom: solid 1px;
}
ul.main-menu {
  /* display: inline-flex;
  list-style: none; */
  margin: 0;
  /* padding: 0;
  float: right;
  z-index: 7;
  width: 100%; */
}

.header_login{
  /* position: absolute; */
  text-align: center;
   display: flex; 
  justify-content: center;
}

.btn-login {
  color: #000;
  border-color: #ffffff;
   border-width: 1px;
   border-style: solid;
   border-radius: 7px;
   position: absolute;
   margin: 0 auto;
}

.btn-info1{
  display: none;
}

}
