
body, html {
    height: 100%;
    min-height: 100%;
    position: relative;
    font-family: 'Josefin Sans', cursive;
    margin:0px auto
}

#main {
    min-width: 100vw;
    min-height: 100%;
    position: relative;
    padding-bottom: 155px;
    background-color: red;
}

#content{
    position: relative;
    background-color: yellow;
    min-height: 100%;
}

#footer {
    background-color: #1B39A6;
    color: #fff;
    position: absolute;
    bottom: 0;
}

#footer .container-fluid {
    padding: 0% 0% 1% 0%;
    background-color: #1B39A6
}

.autor {
    text-align: right;
}

.btn-wsp{
   
    position: fixed;
    width: 55px;
    height: 55px;
    line-height: 58px;
    bottom: 6%;
    right: 3%;
    background: #0df053;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
}



@media only screen and (min-width: 1400px) and (max-width:1920px)  and (min-width:1920px) {
.btn-wsp {
    position: fixed;
    width: 55px;
    height: 55px;
    line-height: 55px;
    bottom: 4%;
    right: 11%;
    background: #0df053;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 30%);
    z-index: 100;
}}