@import url('https://fonts.cdnfonts.com/css/verdana');


.TipopCambio{
    background: -webkit-linear-gradient(175deg, #8039B0  , #68D1D1 );
    display: flex;
    border-bottom: 3px solid #00ABDD;
  }

.homecontenedor{
    height: 80%;
    /* margin: auto; */
    background-color: #fff;
    padding:11% 0% 11% 19%;
    border-top-left-radius: 3%;
    border-bottom-left-radius: 3%;

}


.tc-c2{
  margin: auto 0% auto 12%;
  width: 38%;
  }

.tc-c2-1{
    display: flex;
}

.tc-c2-2{
  width: 15%;
  /* align-items: end; */
  margin-left: 84%;
  margin-top: -16%;
  margin-bottom: -18%;
 
}

.tc-c3{
  margin-left: 9.5%;
  display: flex;
}

.tc-c4{
    padding: 3% 0%;
    width: 82%;
  
  }

.tc-precio2{
    margin-left: -1%;
}
.botoncambio {
    width: 12%;
    margin-bottom: -45px;
    margin-top: -27px;
    margin-left: 57%;
}

.botoncambio:hover{
  cursor:pointer;
}

.tc-tx1 {
  color: #ffffff;
  font-size: 21px;
  text-align: center;
  margin-bottom: 2%;
  margin-left: -2%;
  /* margin-left: 10%; */
  line-height: 22px;
}

.tc-txt2{
    font-size: 25px;
    display: flex;
}

.tc-txt3{
    font-size: 15px;    
    font-weight: 400;
    margin-top: 10%;
}

.tc-txt4{
  position: absolute;
  color: #8039B0;
  font-size: 45px;
  width: 40%;
  font-weight: 600;
  margin-top: 36%;
  left: 15%;
  font-family: 'Verdana', sans-serif;
  line-height: 1;
}

.tc-txt4-2{
    font-size: 30px;
    font-weight: 400;
 
}
  
  .imagentipocambio{
    position:absolute;
    width: 29%;
    top: 15%;
    left: 14%;
    z-index: 1;
    margin-top: 4.5%;
    
  }
  .imgcirculomayor{
    position:absolute;
    width: 32.5%;
    top:  34%;
    margin-left: 11%;
  }


.tc-btn-iniciar{
    width:36%;
    height: 50%;
    background-color: #8039B0;
    border-radius: 7px;
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
    padding: 2%;
    margin-left: 15%;
    margin-top: 3%;
    border: none;
}

.tc-btn-iniciar:hover{
    background-color: #460075;
    color: #ffffff;
}
.imgnube{
   width: 50.5%; 
   top: 3%;
}

.imgcirculo2{
    position:absolute;
    width: 4%;
    top: 101%;
    /* margin-left: 1%; */
}

hr {
    background-color: black;
    width: 76%;
  }

hr:not([size]) {
    height: 2px;
}


.vl-cv {
  border-left: 2px solid #68D1D1;
    height: 70px;
    margin: 0 7%;
  }
  @media only screen
  and (min-width: 0px) and (max-width: 450px){
  .tc-c4 {
    padding: 15% 0%;
    width: 100%;
    font-size: 12px;
}
.TipopCambio {
  display: block;
}
.texto1 {
  font-size: 8px;
}
.tc-txt3 {
  font-size: 10px;
  font-weight: 400;
  margin-top: 10%;
}

.tc-txt2 {
  font-size: 16px;
  display: flex;
}

.imgnube,.imagentipocambio,.imgcirculomayor,.imgcirculo1,.imgcirculo2 {
    display: none;
}
.tc-c3 {
  margin-left: 10%;
  /* display: flex; */
}
.tc-c4 {
  padding: 12% 5%;
  width: 100%;
  font-size: 12px;
}
.vl-cv {
  border-left: 2px solid #68D1D1;
  height: 49px;
  margin: 0 7%;
}

.imput-tipo-cambio1 {
  font-size: 24px;
  font-weight: 500;
  padding: 7% 0%;
}
hr:not([size]) {
  height: 1px;
  margin: 0 auto;
}
.tc-c2 {
  display: block;
  margin: auto;
  width: 75%;

}
.botoncambio {
  width: 19%;
  margin-bottom: -52px;
  margin-top: -29px;
  margin-left: 70%;
}
.tc-txt4 {
  text-align: center;
  width: 90%;
  margin-left: -10%;
  position: relative;
  font-size: 15px;
  color: #68D1D1;
  font-size: 30px;
  font-weight: 600;
  font-family: 'Verdana', sans-serif;
  line-height: 1;
  margin-top: 9%;
}
.tc-txt4-2 {
  font-size: 21px;
  font-weight: 400;
}
.tc-tx1 {
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 2%;
  margin-top: 5%;
  margin-left: -2%;
  /* margin-left: 10%; */
  line-height: 16px;
}

.tc-c2-2 {
  width: 15%;
  /* align-items: end; */
  margin-left: 84%;
  margin-top: -16%;
  margin-bottom: -18%;
}
.homecontenedor {
  height: 54%;
  /* margin: auto; */
  background-color: #fff;
  padding: 11% 0% 11% 1%;
  /* border-top-left-radius: 3%; */
  /* border-bottom-left-radius: 3%; */
  margin-top: 20%;
  border-radius: 3%;
}
.tc-btn-iniciar {
  width: 50%;
  height: 50%;
  background-color: #8039B0;
  border-radius: 7px;
  font-size: 13px;
  color: #ffffff;
  font-weight: 600;
  padding: 2%;
  margin-left: 25%;
  margin-top: 3%;
  border: none;
}
}

  @media only screen
  and (min-width: 451px) and (max-width: 750px) {
    .TipopCambio {
      display: block;
    }

    .imgnube,.imagentipocambio,.imgcirculomayor,.imgcirculo1,.imgcirculo2 {
        display: none;
    }
    
    .tc-c2 {
      display: block;
      margin: auto;
      width: 64%;
  }
  .tc-c4 {
    padding: 6% 5%;
    width: 100%;
    font-size: 12px;
}
.homecontenedor {
  width: 100%;
  padding: 8% 0% 6% 0%;
  border-top-left-radius: 3%;
  border-bottom-left-radius: 3%;
  border-top-right-radius: 3%;
  border-bottom-right-radius: 3%;
  /* margin-top: 20%; */
  /* margin-left: 10%; */
  margin: 0 auto;
  margin-top: 23%;
  margin-bottom: 7%;
}
.tc-c4 {
  padding: 10% 4% 17%;
  width: 100%;
  font-size: 12px;
}
.tc-tx1 {
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  margin-bottom: 2%;
  margin-top: 5%;
  margin-left: -2%;
  /* margin-left: 10%; */
  line-height: 23px;
}

.tc-txt4 {
  text-align: center;
  width: 90%;
  margin-left: -10%;
  position: relative;
  font-size: 15px;
  color: #68D1D1;
  font-size: 52px;
  font-weight: 600;
  font-family: 'Verdana', sans-serif;
  line-height: 1;
  margin-top: 9%;
}
.imput-tipo-cambio1 {
  font-size: 32px;
  font-weight: 500;
  padding: 7% 0%;
}

.tc-c3 {
  margin-left: 10%;
  display: flex;
}

.texto1 {
  font-size: 19px;
}
.tc-txt3 {
  font-size: 19px;
}
.tc-txt2 {
  font-size: 25px;
}
.botoncambio {
  width: 19%;
  margin-bottom: -95px;
  margin-top: -62px;
  margin-left: 70%;
}
.cajapen {
  width: 30%;
  font-size: 14px;
}
.tc-btn-iniciar {
  width: 40%;
  font-size: 15px;
  margin-left: 30%;
  /* margin: 0 auto; */
}
hr {
  margin: 1rem 45px;}

  hr:not([size]) {
    height: 3px;
}
  }

  @media only screen
  and (min-width: 751px) and (max-width: 1000px){


    .imgnube{
     width: 50%;
    }
    .tc-c2 {
      margin: auto 0% auto 8%;
      width: 44%;
  }
  .tc-txt4-2 {
    font-size: 24px;
    font-weight: 400;
}
  .tc-txt4 {
    
      position: absolute;
      color: #8039B0;
      font-size: 38px;
      width: 34%;
      font-weight: 600;
      margin-top: 37%;
      left: 14%;
      font-family: 'Verdana', sans-serif;
      /* line-height: 1; */
}


.imagentipocambio {
  position: absolute;
  width: 27%;
  top: 15%;
  left: 16%;
  z-index: 1;
  margin-top: 4.5%;
}



    .homecontenedor {
      height: 97%;
      /* margin: auto; */
      background-color: #fff;
      padding: 4% 0% 4% 14%;
      border-top-left-radius: 3%;
      border-bottom-left-radius: 3%;
  }
  }

  @media only screen and (min-width: 1500px) and (max-width:2500px){


    .tc-c2 {
      margin: auto 0% auto 12%;
      width: 38%;
  }

  
    .homecontenedor {
      height: 80%;
      /* margin: auto; */
      background-color: #fff;
      padding: 11% 0% 11% 10%;
      border-top-left-radius: 3%;
      border-bottom-left-radius: 3%;
  }

.tc-txt4 {
  position: absolute;
  color: #8039B0;
  font-size: 64px;
  width: 40%;
  font-weight: 600;
  margin-top: 39.5%;
  left: 16%;
  font-family: 'Verdana', sans-serif;
  line-height: 1;
}
  }