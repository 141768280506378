@import url('https://fonts.cdnfonts.com/css/verdana');

#manageProfile {
  background-color: rgba(255, 255, 255, 0.8);
  /* box-shadow: 0 5px 15px 0 rgb(187 195 214 / 40%); */
  border-radius: 20px;
  min-height: 50vh;
}
#contentProfile {
  text-align: center;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  margin: 5% auto;
}
.conttitle{
  margin-top: 3rem;
  width: 25%;
  display: block;
  text-align: left;
  margin-right: 5%;
}

.elementolabel {
  text-align: left;
  color: #68d1d1;
  font-family: "Keep Calm Med";
  font-size: 25px;
  line-height: 1;
}
.elementobajo{
  margin-top: 10%;
  font-family: 'Verdana', sans-serif;
  font-size: 15px;
}

#cardProfile {
  background-color: transparent !important;
  border-color: transparent !important;
}

.card-footer {
  border-color: transparent !important;
  color: #fff !important;
}

#nameProfile {
  font-weight: 600;
}

#imgProfile:hover {
  border: 4px solid #fff;
}

.row-cols-lg-5 > * {
  flex: 0 0 auto;
  width: 27%;
}

.text-light1 {
  --bs-text-opacity: 1;
  color: black !important;
  font-family: 'Verdana', sans-serif;
  font-size: 15px;
}
.text-light2 {
  --bs-text-opacity: 1;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Verdana', sans-serif;
  color: #1B39A6;
}

.mt-2Select {
  margin-top: 0.5rem !important;
  /* justify-content: center; */
  align-items: center;
}


.mt-2 {
  margin-top: 2rem !important;
}

.containermf{
  width: 69%;
  margin: 0 auto;
}

@media only screen
and (min-width: 0px)
and (max-width: 750px) {

  .TipopCambio {
    display: block;
  } 
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

.elementolabel{
  text-align: center;
}
#contentProfile{
  display: block;
}
.elementobajo {
  text-align: center;
}
.text-light2 {
  font-size: 12px;
}
.conttitle{
  width: 100%;
}
}