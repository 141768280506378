@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.wrapper{
  display: inline-flex;

  height: 58px;
  width: auto;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
}
.wrapper .option{
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapper .option .dot{
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.wrapper .option .dot::before{
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #7C40D6;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
.optAccion[type="radio"]{
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2{
  border-color: #7C40D6;
  background: #7C40D6;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot{
  background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before{
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span{
  font-size: 20px;
  color: #000;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span{
  color: #fff;
}

#cardOperation {
  background-color: #62DDDD;
}

#calc{
  background-color: #62DDDD;
}

#montoCambio {
  font-size: 1.8rem;
}

.circle{
    border: 4px solid #fff;
    height: 180px;
    width: 180px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
}

@media only screen and (max-width: 768px) {
  
  .wrapper{
    display: inline-block;
    margin-left: -0.7rem;
  }

  .wrapper .option{
    margin-bottom: 0.5rem;
  }
}