@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url('https://fonts.cdnfonts.com/css/verdana');
@font-face {font-family: "Keep Calm Med"; 
  src: url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.eot"); 
  src: url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.eot?#iefix") 
  format("embedded-opentype"), url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.woff2") 
  format("woff2"), url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.woff") format("woff"),
   url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.ttf") format("truetype"), 
   url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.svg#Keep Calm Med") format("svg"); }


  
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*
body,
input {
  font-family: "Poppins", sans-serif;
}*/

.app {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
}

.app:before {
  content: "";
  position: absolute;
  width: 2000px;
  height: 1700px;
  border-radius: 50%;
  /* background: linear-gradient(-45deg, #4481eb, #04befe); */
  background: linear-gradient(-45deg, #31B8BD, #BFF3F5);
  top: -10%;
  right: 44%;
  transform: translateY(-50%);
  z-index: 6;
  transition: 1.8s ease-in-out;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 46%;
  left: 71%;
  transform: translate(-50%, -50%);
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
  transition: 1s 0.7s ease-in-out;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5rem;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  transition: 0.2s 0.7s ease-in-out;
}

form.sign-in-form {
  z-index: 2;
}

form.sign-up-form {
  z-index: 1;
  opacity: 0;
}

.title {
  font-size: 29px;
  color: #8039B0;
  margin-bottom: 10px;
  font-family: "Keep Calm Med";
}

.sigup {
  font-weight: 600;
  text-decoration: underline;
  color: #8039B0;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
}
.sigup:hover{
  color: #6900af;
}

.input-field {
  max-width: 380px;
  width: 50%;
  height: 45px;
  background-color: #f0f0f0;
  margin: 10px 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  border: 1px solid #7a7878;
}

.input-field span {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  outline-width: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
  font-size: 17px;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.btn-user {
  width: 160px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #1B39A6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  transition: 0.5s;
  font-size: 15px;
  padding: 12px 15px;
}

.btn-user:hover {
  background-color: #021a72;
}

.btn-user1 {
  width: 150px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #68D1D1;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  transition: 0.5s;
  font-size: 15px;
  padding: 12px 15px;
}
.btn-user1:hover {
  background-color: #00bdbd;
}

.terycon{
  color: #8039B0;
}
.terycon:hover{
  color: #6900af;
}


.social-text {
  display: flex;
  justify-content: center;
}

.btn-link { 
  padding: 0 !important;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  border: 1px solid #333;
  margin: 0 0.45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  border-radius: 50%;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.panels-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 7;
}

.left-panel {
  pointer-events: all;
  /* padding: -1rem 20% 2rem 8%; */
}

.right-panel {
  pointer-events: none;
  /* padding: -1rem 12% 2rem 17%; */
}

.panel .content {
  color: #fff;
  transition: 0.9s 0.6s ease-in-out;
  width: 25%;
  margin-right: 28%;
  margin-bottom: 30%;
}



.textsig1{
  color: #1B39A6;
  font-size: 18px;
  font-family: 'Verdana', sans-serif;
  line-height: 1.2;
}

.crear{
 margin-top: 8% !important;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.image {
  width: 90%;
  transition: 1.1s 0.6s ease-in-out;
  margin-top: -35%;
  margin-right: 10%;
}

.primera{
    margin-top: -20%;
    margin-left: -1%;
    width: 70%;
}

.segunda{
  margin-top: -40%;
  margin-right: 20%;
  width: 85%;
}

.right-panel .content,
.right-panel .image {
  transform: translateX(800px);
}

.app.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.app.sign-up-mode .left-panel .image,
.app.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.app.sign-up-mode .right-panel .content,
.app.sign-up-mode .right-panel .image {
  transform: translateX(0px);
}

.app.sign-up-mode .left-panel {
  pointer-events: none;
}

.app.sign-up-mode .right-panel {
  pointer-events: all;
}

.app.sign-up-mode .signin-signup {
  left: 28%;
}

.app.sign-up-mode form.sign-in-form {
  z-index: 1;
  opacity: 0;
}

.app.sign-up-mode form.sign-up-form {
  z-index: 2;
  opacity: 1;
}
.imginicio{
  width: 80%;
}


.chk-politica1 {
  margin-left: -2rem;
}

.chk-politica2 {
  margin-left: -2rem;
}

@media only screen
and (min-width: 0px) and (max-width: 750px)
{
  .app {
    min-height: 650px;
    height: 780px;
  }
  form {
    top: 11%;
    padding: 0% 0rem;
    /* padding-top: 24%; */
}
  
  .app:before {
    width: 1500px;
    height: 1500px;
    left: 30%;
    bottom: 68%;
    transform: translateX(-50%);
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }
  .app.sign-up-mode form.sign-up-form {
    z-index: 2;
    opacity: 1;
    margin-top: -9%;
}
  .signin-signup {
    width: 100%;
    left: 50%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }
  .panel .content {
    color: #fff;
    transition: 0.9s 0.6s ease-in-out;
    width: auto;
    margin-right: 0%;
    margin-bottom: 10%;
}
  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
  .input-field {
    max-width: 380px;
    width: auto;
    height: 50px;
    background-color: #f0f0f0;
    margin: 9px 0;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    border: 1px solid #7a7878;
}

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .panel .content {
    padding-right: 15%;
    transition: 0.9s 0.8s ease-in-out;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .image {
    width: 200px;
    transition: 0.9s 0.6s ease-in-out;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .right-panel .content,
  .right-panel .image {
    transform: translateY(300px);
  }

  .app.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .app.sign-up-mode .left-panel .image,
  .app.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .app.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
    left: 50%;
  }
  
  .form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }

  .panel .content {
    padding: 0.5rem 1rem;
  }

  .app:before {
    bottom: 72%;
    left: 50%;
  }

  .app.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
  
  .content-register {
    text-align: left !important;
  }
}


