@import url('https://fonts.googleapis.com/css?family=Bungee+Inline');
 
body, html {
    height: 100%;
    min-height: 100%;
    position: relative;
    
}

/* import flechaPasos from '../../assets/img/flecha_pasos.svg'; */

.cont-celeste {
    height: 100%;
    background: #62dddd;
    bottom: 10;
    padding-bottom: 20px;

    /* background-position-x: 0rem; */

}

.paso {
    
    background-image: url("../../assets/img/flecha_pasos.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: auto;
    bottom: 10  ;
    position: relative;
    margin-bottom: 15px;
    
    /* background-position-x: 0rem; */

}

.cuadro-tipo-cambio{
    padding-top: 40px;
}

/*=======================================*/
/* Estilos para Componente Tipo Cambio */
/*=======================================*/
.imput-tipo-cambio{
    border: 0;
    background: transparent;
    /* width: 50%; */
}

.span-tipo-cambio{
    display: block;
    /* width: 50%; */
}

.caja-tipo-cambio{
    -webkit-text-size-adjust: 100%;
    --color-1: #192131;
    color: var(--color-1);
    visibility: visible;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    margin: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    font-style: inherit;
    font-weight: inherit;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    padding: 0px 0px;
    font-family: 'Montserrat';
    background: #d8dcdf !important;
}



.texto-tipo-cambio1{
    -webkit-text-size-adjust: 100%;
    visibility: visible;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    font-style: normal;
    margin: 0;
    vertical-align: baseline;
    display: inline-block;
    background: #fff;
    border-radius: 3px;
    appearance: none;
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 50px;
    line-height: 99.61%;
    letter-spacing: -0.03em;
    color: #061026;
    background-color: rgba(0,0,0,0);
    border: 0px;
    padding: 0px;
    max-width: 100%;
}

.texto-tipo-cambio2{
    -webkit-text-size-adjust: 100%;
    color: var(--color-1);
    visibility: visible;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    font-style: inherit;
    font-weight: inherit;
    font-family: 'Montserrat';
    background-color: #060f25;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 35%;
    position: relative;
}

.texto-texto-tc {
    -webkit-text-size-adjust: 100%;
    --color-300: #00E2C1;
    color: var(--color-300);
    visibility: visible;
    -webkit-box-direction: normal;
    margin: 0;
    border: 0;
    outline: 0;

    font-size: 25px;
    vertical-align: baseline;
    font-family: 'Bungee Inline', cursive;
    padding: 21px 10px;
    text-align: center;
}

.btn-iniciar {
    width: 80%;
    margin-top: 20px;
    background-color: #00E2C1;
    border-radius: 10pt;
    font-size: 20px;
    color: #803cb0;
    font-weight: 600;
}

.flechas-cambiar-moneda {
    /* position: fixed; */
    width: 65px;
    height: 55px;
    line-height: 55px;
    bottom: 30px;
    right: 30px;
    background: #803cb0;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
    cursor: pointer
}

.linea1{
    width: 100%;
}


.flechas-cambiar-moneda:hover {
    color: #00E2C1;
  }


  .alert-success {
   width: 80%;
   margin-left: 10%;
    background-color: #ffffff !important;
    border-color: #615b5b !important;
}
.contconfirmacion{
    background-color: #803cb0;
    color: #ffffff !important;
    height: 100%;
/*    
    display: flex; */
}
.continfo12{
    color: #ffffff;
    font-size: 12px;
    margin-left: 5%;
    margin-top: -7%;
    position: absolute;
}

.contconfirmacion1{
    background-color: #803cb0;
    color: #ffffff !important;
    height: 100%;
    padding: 5% 2%;
    display: flex;
}

.continfverificarp1{
    display: flex;
    width: 68%;
}

.continfverificarp2{
    display: flex;
    width: 50%;
}

.continfverificarp4{
    display: flex;
    width: 35%;
}

.continfverificarp3{
    display: flex;
    width: 30%;
}

.continfverificarp5{
    display: flex;
    width: 50%;
}

.continfverificarp6{
    display: flex;
    width: 88%;
}

.continfverificar1{
    display: flex;

}

.alert {
    position: relative;
    padding: 0% !important;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    align-items: center !important;
}
.textoper{
    font-size: 14px !important;
    color: #803cb0 !important;
    font-weight:400;

    text-align: right;
}

.textoper1{
    font-size: 14px !important;
    color: #803cb0 !important;
    font-weight:400;
    margin-left: 10%;
}

.textnc{
    font-size: 20px !important;
    text-align: center;
    margin-top: -5%;
    color: #000000;
    
}

.continftotal{
    text-align: center;
}

.textnc1{
    font-size: 17px !important;
    text-align: center;
    color: #000000;
    
}

.txtrecibes1{
    font-size: 20px !important;
}

.txtrecibes{

    margin-left: 1%;
    margin-top: 4%;
    font-size: 23px !important;
}

.txtopreinfo{
    margin-right: 10%;
    margin-top: 4%;
    font-size: 23px !important;
}

.botonesconfirmar{
    display: flex;
}

.contcconfirmar{
    display: flex;
}
.botonesconfirmar1{
    display: flex;
    align-items: center;
}

.btnplomo{
    margin-left: 33% !important;
    width: 100% !important;
}

.ndotxt1{
    color: #803cb0;
    font-size: 16px;
}

.ndotxt2{

    font-size: 13px;
}


@media only screen
and (min-width: 0px) and (max-width: 750px)
{

.alert-success {
    width: 100%;
    margin-left: 0%;
    background-color: #ffffff !important;
    border-color: #615b5b !important;
}

.txtrecibes {
    margin-left: 0.5%;
    margin-top: 4%;
    font-size: 13px !important;
}
.btnplomo {
    /* margin-left: 33% !important; */
    width: 50% !important;
    margin: 0 auto !important;
}

.txtopreinfo {
    margin-right: 2%;
    margin-top: 3%;
    font-size: 15px !important;
}

.botonesconfirmar {
    display: block;
}
}

@media only screen
  and (min-width: 751px) and (max-width: 1000px){}

 @media only 
	screen and (min-width: 1500px) and (max-width:1920px){}