.p-contenedor{
    background-color: #8039B0;
    padding: 4% 0% 0 0%;
}

.p-txt{
    margin-left: 10%;
}
.p-txt1{
    color: #68D1D1;
    font-size: 31px;
    font-weight: 500;
}
.p-txt2{
    color: #ffffff;
    font-size: 21px;
}
.p-txt3{
    width: 27%;
    color: #ffffff;
    margin-top: 5%;
    margin-left: 3%;
    line-height: 1.2;
    font-size: 14px;
}
.p-txt4{
    width: 27%;
    color: #ffffff;
    margin-top: 3%;
    margin-left: 3%;
    line-height: 1.2;
    font-size: 14px;
}
.p-txt5{
    color: #ffffff;
    font-size: 13px;
}



.p-paso1{
    width: 34%;
}


.p-cont-1{
    display: flex;
    width: 30%;
}

.p-cont-2{
    display: flex;
    width: 30%;
    margin-left: -7.9%;
}


.p-cont-3{
    display: block;
    position: relative;
    width: 10%;
    margin-left: -1%;
    margin-top: -5%;
}

.p-cont-3:hover{
    cursor:pointer;
}

.pasosconteiner{
    margin-top: 3%;
    display: flex;
    width: 100%;
    margin-left: 10%;
}

.nube{
    position: absolute;
    margin-top: -4%;
    margin-left: 83.6%;
    width: 16.4%;
}

.btncs{
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    line-height: 23px;
    height: 18%;
    background-color: #5b1d85;
    border-radius: 8px;
    color: #ffffff;
    border: none;
    padding: 9%;
    font-size: 15px;

}



@media only screen
and (min-width: 0px) and (max-width: 750px){


    .p-paso1 {
        width: 15%;
    }

        .nube{
            display: none;
        }
        .p-txt {
            margin-left: 2%;
        }
    
        .p-txt1 {
            font-size: 25px;
        }
        .p-txt2 {
            font-size: 12px;
        }
        .pasosconteiner {
            margin-left: 0%;
        }
        .p-cont-1 {
            display: flex;
            width:auto;
        }
    
        .p-paso1 {
            width: 33%;
            margin-left: 5%;
            margin-bottom: 5%;
        }
    
        .p-cont-2 {
            width: auto;
            margin-left: -10%;
        
        }
        .p-txt3 {
            width: 38%;
            margin-top: 2%;
            margin-left: 1%;
            line-height: 1.2;
            font-size: 12px;
        }
    
        .p-txt4 {
            width: 28%;
            margin-top: 0%;
            margin-left: 1%;
            line-height: 1.2;
            font-size: 12px;
        }
    
        .p-txt5 {
            text-align: center;
            font-size: 8px;
    
        }
        .play{
            width: 30%;
            margin-left: 35%;
        }
    
        .p-cont-3 {
            display: block;
            width: auto;
            margin-left: -10%;
            align-items: center;
            margin-top: 5%;
        }
    
    .p-contenedor {
        background-color: #8039B0;
        padding: 4% 0% 0 0%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .p-txt2 {
        color: #ffffff;
        font-size: 15px;
        line-height: 18px;
        margin-top: 5%;
        text-align: center;
    }
    .p-txt1 {
        color: #68D1D1;
        font-size: 22px;
        font-weight: 500;
        line-height: 87%;
        text-align: center;
    }
    .nube{
        display: none;
    }

    .btncs {
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        line-height: 12px;
        height: 14%;
        background-color: #5b1d85;
        border-radius: 8px;
        color: #ffffff;
        border: none;
        padding: 7%;
        margin-top: 16%;
        margin-bottom: -9%;
        font-size: 6px;
    }
    .p-cont-3 {
        display: block;
        width: auto;
        margin-left: -10%;
        align-items: center;
        margin-top: -5%;
    }
}

@media only screen
and (min-width: 751px) and (max-width: 1000px){

}

@media only 
  screen and (min-width: 1500px) and (max-width:2500px){
    .p-txt3, .p-txt4 {
        width: 31%;
        color: #ffffff;
        margin-top: 2%;
        margin-left: 3%;
        line-height: 1.2;
        font-size: 34px;
    }

    .p-txt2 {
        width: 80%;
        color: #ffffff;
        font-size: 32px;
    }
    .p-txt1 {
        color: #68D1D1;
        font-size: 49px;
        font-weight: 500;
    }
    .btncs {
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        line-height: 32px;
        height: 18%;
        background-color: #5b1d85;
        border-radius: 8px;
        color: #ffffff;
        border: none;
        padding: 9%;
        margin-top: 21%;
        font-size: 27px;
    }
    .p-txt5 {
        color: #ffffff;
        font-size: 28px;
    }
  }


