@import url('https://fonts.cdnfonts.com/css/keep-calm');

@font-face {font-family: "Keep Calm Med"; src: url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.eot"); src: url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.woff") format("woff"), url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.svg#Keep Calm Med") format("svg"); }



.contsocios{
    margin: 3% 5%;
    display: flex;
}

.s-txt1{
    color: #8039B0;
    font-size: 22px;
    line-height : 25px;
    font-weight: 400;
    width: 35%;
    margin-top: 5%;
}

.s-txt2{
    font-size: 30px;
    font-family: 'Keep Calm', sans-serif;
    font-weight: 600;
}

.socialg1{
    display: block;
}

.socialg2{
    display: block;
}

.morocapita {
    width: 22%;
    margin-left: 2%;
    margin-top: 4%;
  
}


.avi{
    width: 8%;
    margin-left: 5%; 
}

.Kombu {
    width: 11%;
    margin-left: 5%; 
}


.anconsurimg,  .campiña {
    width: 20%;
    margin-left: 5%; 
    margin-top: 3%;
}

.comsitec {
    margin-top: 4%;
    width: 18%;
    margin-left: 2%;
}

.CR{
    width: 13%;
    margin-left: 5%;
}

.staton{
    margin-top: 4%;
    width: 20%;
    margin-left: 5%;
}

.visualmedia{
    margin-top: 4%;
    width: 8%;
    margin-left: 5%;
}


@media only screen
and (min-width: 0px) and (max-width: 750px){

    .contsocios{
        margin: 3% 5%;
        display: block;
    }

    .s-txt1 {
        color: #8039B0;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        width: 86%;
        margin: 0 auto;
        padding-bottom: 2%;
    }
    
    .s-txt2 {
        font-size: 17px;
        font-family: 'Keep Calm', sans-serif;
        font-weight: 600;
    }

}

@media only screen
  and (min-width: 751px) and (max-width: 1000px){}

 @media only 
	screen and (min-width: 1500px) and (max-width:2500px){

        .s-txt1 {
            color: #8039B0;
            font-size: 42px;
            line-height: 40px;
            font-weight: 400;
            width: 31%;
            margin-top: 5%;
        }
        .s-txt2 {
            font-size: 65px;
            font-family: 'Keep Calm', sans-serif;
            font-weight: 600;
        }
    }