

.conteinerT{

   
    flex: 0 auto;
    width: 60%;
    /* padding: 1rem 1rem; */
}

/* #calc {
    background-color: #ffffff;
    width: 100%;
    margin: 0 auto;
    padding: 0px;
} */

.conti {
    width: 100%;
    margin: 0% 0% 0% 5%; 
}
.conti1 {
    width: 90%;
    margin: 0% 0% 0% 5%;
}

.reloj{
    margin: 0 auto;
    width: 150%;
    margin-left: 65%;

}

.col-md-331 {
    /* padding: 3% 3%; */
    /* margin-left: -40%; */

    width: 55%;
}
/* .mall{
    width: 450%;

} */
.form-select1{
    width: 100%;
    padding: 0.778rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.5rem;

}


/* .row121 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    width: 600px;
} */

.text-pur {
  color: #8039B0;
  margin-top: 5%;
  
}
.text-azul {
    color: #00ABDD;
    font-size: 16px;
    margin-top: 5%;
    margin-bottom: 5%;
  }


  .text-morado{
    color: #8039B0;
    font-size: 16px;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .text-contblue{
    background-color: #180fbb;
    color: #ffffff;
    font-size: 18px;
    margin-top: -35%;
    margin-bottom: -15%;
    MARGIN-RIGHT: -36%;
    margin-left: -35%;
    PADDING: 15% 40%;
    text-align: center;
  }
  .tiemporelog{
    margin-left: -36%;
    margin-right: 25%;
    margin-top: 25%;
    width: 69%;
  }

  .textrelog{
    color: #8039B0;
    margin-top: 42%;
    margin-right: -23%;
    text-align: right;
  }

  .textrelog2{
    font-size: 26px;
    margin-left: 78%;
    margin-top: -17%;
    margin-right: -29%;
  }

  .contrelogs{
    display: flex;
  }

.mb-31 {
    width: 30%;
}

.felchita{
    width: 43px;
    margin-top: 15%;
    margin-left: -9%;
}



.mtd{
    border-radius: 6px;
    border: none;
    /* margin-left: 5%; */
    background-color: #8039B0 !important;
    padding: 3%;
    color: #fff !important;
    font-size: 15px !important;
    text-transform: uppercase !important;
    width: 30% !important;
    font-weight: bold !important;
    padding: 12px 15px !important;


}
.operbntconfirmar{
    width: 60% !important;
    font-size: 13px !important;
}

.mtd:hover{
    background-color: #6900af !important;
    color: #fff !important;

  }

.btn-secondary {
    margin-left: 1rem;;
    border-radius: 6px;
    border: none;
    /* margin-left: 5%; */
    background-color: #b1b4b3 !important;
    padding: 3%;
    border: none !important;
    color: #fff !important;
    font-size: 15px !important;
    text-transform: uppercase !important;
    width: 30% !important;
    font-weight: bold !important;
    padding: 12px 15px !important;

    }


.btn-secondary:hover{
    background-color: #5a5a5a !important;
    color: #fff !important;
}

.rowbotenes {
    display: flex;
}

/* 
 */
/* .cls-1{fill:#803cb0;}.cls-2{fill:#68d1d1;fill-rule:evenodd;} */

.textterminar{
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.txtterm1{
    font-size: 15px;
    
    color: #000000;
}

.wt {
    padding: 0% 0;
    overflow: hidden;
}

.wizard .content {
    width: 100%;
    /* padding: 20px 10px 10px 0px; */
    background-color: #fff;
    margin: 0 auto;
}
.text-negro{
    font-size: 13px;
}
.txt10{
    color: #8039B0;
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
}

.txt11{
    text-align: center;
    /* margin-left: -14%;
    margin-bottom: -7%; */
}



.containertotal{
    width: 60%;
    margin: 0 auto;
}

@media only screen
and (min-width: 0px) and (max-width: 750px)
{ 
    .containertotal{
        width: 70%;
        margin: 0 auto;
    }

    .conti{
        width: 90%;
    }

    .col-md-331 {
        width: 80%;
        margin: 0 auto;
    }
    .text-pur {
        margin-top: 0%;
    }

    .felchita{
        width: 44px;
        margin-top: -125%;
        margin-left: 60%;
    }

    .text-azul{
        text-align: center
    }
    .rowbotenes {
        display: block;
    }

    .mtd{
        width: 100% !important;
        margin: 5% 0%;
    }

    .btn-secondary {
        width: 100% !important;
        margin-left: 0rem;
    }

}
.wizard .steps > ul li a {
    display: inline-block;
    text-decoration: none !important;
    height: 100%;
    width: auto !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(153, 155, 163, 0.1);
    padding: 16px 17px 16px;
}

.tuti{
    margin: 10%;
}


.agregado{
    display: flex;
    width: 70%;
    margin: 0 auto
}


.col-md-ct{
    width: 25%;
    padding: 3% 0%;
    margin-top: 16%;
    background-color: #fff;
}
