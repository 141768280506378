.card-bodyRef {
    flex: 0 1 auto;
    padding: 0rem 1rem;
}
.cajacopia{
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
    width: 35%;
    margin-right: 10%;
}



.col-md-81 {

    width: 100%;
}



.btn-primaryRef {

    color: #fff;
    background-color: #00ABDD;
    border-color: #00ABDD;
    border: 0px solid transparent;
    border-radius: 0.25rem;
    width: 35%;
    height: 75px;
}
.alert-warning {
    width: 30%;
    height: 100%;
    border-color: black;
    background-color: #fff;
}

.refer {
    position: relative;
    text-align: center;
    width: 65%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    font-size: 25px;
    border: none;
    border-radius: 0.25rem;
    margin-top: 10px;
}
.txtingresoRef{
    font-size: 15px;
    background-color: #00ABDD;
    color:#fff;
    width: 50%;
    padding: 20px 5px;
    line-height: 15px;
}

.txtingreso{
    padding: 7px 20%;
    margin: 10px;
}
.text-purRef {
    color: #8039B0;
    margin-top: 5%;
}

.text-greyRef {
    color:#3f4141;
    width: 100%;
    margin-bottom: 3%;
}
.referidos{
    display : flex;
    flex-direction : row;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

}


.cajaref{
    display: flex;
}

@media (max-width: 992px) {

.cajacopia{
    width: 100%; 
    margin-bottom: -40px;
    margin-top: 20px;
}

.cajaref{
    display: block;
}
.txtingresoRef {
    font-size: 12px;
    padding: 15px 3px;
}
.txtingreso {
    padding: 7px 15%;
    margin: 10px;
}
.text-purRef {
    text-align: center;
}
.text-greyRef{
    text-align: center;
}
}