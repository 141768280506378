.c-cont1{
    display: flex;
    background-color: rgb(219 221 233);
}

.c-cont2{
    margin-top: 2%;
    margin-left: 20%; 
}

.c-cont3{
    margin: 2% -7.5% 0% -6%
}


.c-cont4{
    margin-top: 2%;
}



.c-txt1{
    width: 60%;
    color: #8039B0;
    font-size: 15px;
    text-align: center;
    margin: 4% -6%;
    font-weight: 400;
    line-height : 18px;
  
}

.c-txt2{
    width: 50%;
    color: #8039B0;
    font-size: 15px;
    text-align: center;
    margin: 4% -4%;
    font-weight: 400;
    line-height : 18px;
}


.c-txt3{
    width: 45%;
    color: #8039B0;
    font-size: 15px;
    text-align: center;
    margin: 4% 0%;
    font-weight: 400;
    line-height : 18px;
}


.imgRapido{
    width:52%;
}

.imgSeguro{
    width: 50%;
}

.imgAhorro{
    width: 43%;
}
.btnmodalv1{
    background-color: #8039B0 !important
    ;
}

.btnmodalv1:hover{
    background-color: #591288 !important
}
@media only screen
and (min-width: 0px) and (max-width: 750px){

.c-txt1, .c-txt2, .c-txt3{

  display: none;
}
.imgAhorro, .imgSeguro  {
    width: 52%;
    padding-bottom: 8%;
}



}
@media only screen
  and (min-width: 751px) and (max-width: 1000px){}

 @media only 
	screen and (min-width: 1500px) and (max-width:2500px){

.c-txt1 {
    width: 60%;
    color: #8039B0;
    font-size: 25px;
    text-align: center;
    margin: 4% -6%;
    font-weight: 400;
    line-height: 25px;
}
.c-txt2 {
    width: 52%;
    color: #8039B0;
    font-size: 25px;
    text-align: center;
    margin: 4% -4%;
    font-weight: 400;
    line-height: 25px;
}
.c-txt3 {
    width: 45%;
    color: #8039B0;
    font-size: 25px;
    text-align: center;
    margin: 4% 0%;
    font-weight: 400;
    line-height: 25px;
}

}