

.container-tyc{
    padding: 5%;
    background-color: rgb(255, 255, 255);

}




/* .tyc1{
    padding-left: 1%;
    list-style-type: lower-alpha;
} */

.tyc3{
    font-weight: 500;
}


.contTerminos{
    text-align: justify;
    color: rgb(100, 100, 100);
}


.titleTerminos{
text-align: center;
color: #8039B0 !important ;
}