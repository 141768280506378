@font-face {font-family: "Keep Calm Med"; 
    src: url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.eot"); 
    src: url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.eot?#iefix") 
    format("embedded-opentype"), url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.woff2") 
    format("woff2"), url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.woff") format("woff"),
     url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.ttf") format("truetype"), 
     url("//db.onlinewebfonts.com/t/52c3a1cf674133b3ec2c4c316180ae14.svg#Keep Calm Med") format("svg"); }
     
     @import url('https://fonts.cdnfonts.com/css/verdana');
  
     .rowf {
     
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        /* flex-wrap: wrap; */
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }  
    .rowf > * {
        flex-shrink: 0;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }

    .form-check-input:checked {
        background-color: #68d1d1 !important;
        border-color: #68d1d1 !important;
    }

    .form-check-input[type="checkbox"] {
        border-radius: 2.25em;
    }
    .contf {
    
        /* padding: 20px 10px 10px 0px; */
/*   
        margin: 5% auto; */
    }
/* TITULO */
.elementotitulo {
    color: #68d1d1;
    font-family: "Keep Calm Med";
    font-size: 25px;
    line-height: 0.5;
    width: 60%;

   
}
@media (max-width: 767px) {
    .elementotitulo{ width: 100%;}

    .wizard .content p {
        width: 100%;
    }

 }

/* TEXTO 1 */
.wizard .content p {
    font-family: 'Verdana', sans-serif;
    width: 90%;
}

/* TEXTO 2 */
.wizard .content .p1 {
    color: #030303;
    font-family: 'Verdana', sans-serif;
    width: 90%;
    font-size: 14px;
}
/* TEXTO DE LAS CAJAS */
.wizard .content .form-group label {
    font-weight: 500;
    font-family: 'Verdana', sans-serif;
    font-size: 13px;
}
/* CAJA SELECT */
.form-select {
    padding: 0.778rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.5rem;
}

/* CAJA DE INGRESO */
.form-control {
    padding: 0.778rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.5rem;
}
/* MODAL QUESTION */
.btnquestions {
    border: none;
    width: 2%;
    background-color: #00000000;
}

/* BOTON CONTINUAR */
/* .wizard .actions .btnStep {
    border-radius: 6px;
    border: none;
    margin-left: 1rem;
    background-color: #68d1d1;
    padding: 12px 15px;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
} */


.wizard .actions .btnStep :hover{
    
    background-color:#00bdbd;
}
/* BOTON CANCELAR */

.btnCancelar {
    border-radius: 6px;
    border: none;
    margin-left: 1rem;
    background-color: #b1b4b3;
    padding: 12px 15px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.btnCancelar:hover{
    background-color: #5b5c5c;
}
/* COLOR DEL RADIO BUTTON */
.form-check-input1:checked {
    background-color: #68d1d1;
    border-color: #68d1d1;
}

.form-check-input[type="checkbox"] {
    border-radius: 0.55em !important;
}

.cls-1{fill:#803cb0;

    

}
.cls-1,.cls-2{fill-rule:evenodd;}
.cls-2{fill:#ffffff;}


/* .wizard .steps > ul li.current .bd-wizard-step-icon, .wizard .steps > ul li.done .bd-wizard-step-icon {
    background-color: #803cb0;
    color: #68d1d1;
    fill:#68d1d1;
    
} */
.cls-1-1{fill:#ffffff00;}.cls-1-1,.cls-2-1{fill-rule:evenodd;}.cls-2-1{fill:#ffffff;}

/* MODAL */
.modal1 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.table {
    padding: 0.5rem 3.2rem;
    background-color: #0000000a;
    border-bottom-width: 0px;
    line-height: 1.5rem;
    font-family: 'Verdana', sans-serif;
}

.tableHistory {
    /* padding: 0.5rem 3.2rem;
    background-color: #0000000a; */
    border-bottom-width: 0px;
    line-height: 1.5rem;
    font-family: 'Verdana', sans-serif;
}

.modal-content1 {
    position: relative;
    background-color: #fff;
    top: 115px;
    width: 90%;
    border-radius: 15px;
    height: 95%;
    margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.2);

}
.modal-header {
    background-color: #68d1d1;
    padding: 0.8rem 1rem 0rem 25%;
    text-align: end;
    border-radius: 15px 15px 0px 0px;
}
.btn-primary {
    margin-left:10% ;
    margin-right: 10%;
    color: #fff;
    background-color: #68d1d1;
    border-color: #68d1d1;
    text-transform: uppercase;
    font-weight: bold;
    padding: 16px 25px;
    width: 36%;
}
.btn-primary:hover{
    background-color: #00bdbd;
    border-color: #00bdbd;
}
/* .btn-secondary {
    color: #fff;
    background-color: #b1b4b3;
    border-color: #b1b4b3;
    text-transform: uppercase;
    font-weight: bold;
    padding: 14px 45px;
} */

.text-headermodal {
    --bs-text-opacity: 1;
    color: #ffffff;
    font-family: 'Verdana', sans-serif;
    font-weight: bold;
    font-size: 21px;
    padding: 0% 0% 0% 26%;
    margin-bottom: -5%;
}

#title{
    font-size: 30px;
}

.actmodelbutton{
    width: 100%;
}

.colp-md-3{
    flex: 0 0 auto;
    width: 25%;
}


.datosa{
    width: 100%;
}

.pasod{
    font-size: 13px;
}
.contprovincia{
    width: 94%;
}
.form-group-celular{
    width: 28%;
}

.form-group-direccion{
    width: 60%;
}

.contnumeros{
    margin-left: -4%;
}

.btn-primary {
    color: #fff;
    background-color: #68d1d1;
    border-color: #68d1d1;
}

.modalt2{
    font-size: 15px;
    text-align: left;
}
.modalt1{
    font-size: 15px;
    text-align: right;
}
.imgmodal{
    width: 21%;
    margin-bottom: -3%;
}

.btn-primary-modal {
    margin-left: 10%;
    border: none;
    color: #fff;
    background-color: #68d1d1;
    border-color: #68d1d1;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 25px;
    width: 35%;
    font-size: 13px;
    border-radius: 0.3rem;
}

.btn-cerrar-modal {
    margin-left: 10%;
 border: none;
    color: #fff;
    background-color: #b1b4b3;
    border-color: #b1b4b3;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 25px;
    width: 35%;
    font-size: 13px;
    border-radius: 0.3rem;
}


@media only screen
and (min-width: 0px) and (max-width: 750px)

{
    .col-md-3 {
        width: 25%;
        display: block;
    }

    .btn-cerrar-modal {
        margin-top: 5%;
        border: none;
        color: #fff;
        background-color: #b1b4b3;
        border-color: #b1b4b3;
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px 25px;
        width: 100%;
        font-size: 13px;
        border-radius: 0.3rem;
        /* display: flex; */
        MARGIN: 4% AUTO 0% AUTO;
    }
    .btn-primary-modal {
        margin-left: 0%;
        border: none;
        color: #fff;
        background-color: #68d1d1;
        border-color: #68d1d1;
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px 25px;
        width: 100%;
        font-size: 13px;
        border-radius: 0.3rem;
        /* MARGIN: 2%; */
    }

    
    .col-md-9 {
        flex: 0 0 auto;
        width: 100%;
        padding: 6%;
    }
    .btnquestions {
        width: 5%;
        /* vertical-align: middle; */
        margin-top: 0%;
        margin-left: 0%;
    }


    .btn-primary {
        width: 100%;
        margin: 5% 0;
    }

    .btn-primary:hover{
        background-color: #00bdbd;
        border-color: #00bdbd;
    }

    .btnCancelar:hover{
        background-color: #5b5c5c;
    }

    .text-headermodal {
        font-size: 16px;
    }


    .modal-header {
        padding: 5% 5% 0% 20%;
    }

    .modal-content {
        position: relative;
        border-radius: 15px;
        width: 90%;
        top:100px
    }

    .rowf{
        display: block;
        margin-bottom: 20%;
    }

    .cold-md-3 {
        width: auto;
        margin-right: 0%;
    }
    .imageidentidad {
        display: none;
    }
    .form-group-celular {
        width: auto;
    }
    .contnumeros {
        margin-left: 0%;
    }
    .mb-3 {
        width: auto;
    }
    .form-group-direccion {
        width: auto;
    }
 }

 @media only screen
  and (min-width: 751px) and (max-width: 1400px){
    .col-md-4 {
        flex: 0 0 auto !important;
        width: 35% !important;
    }
  }


 @media only 
	screen and (min-width: 1500px) and (max-width:1920px){
     
    }
