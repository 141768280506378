@import url('https://fonts.cdnfonts.com/css/verdana');

.footer-contendor{
    display: flex;
   
    margin-top: 3%;
 
}

.fcont0{
margin-left: 10%;
    width: 10%;
}


.fcont2{

    width: 10%;
}

.fcont3{
    width: 11%;
}

.fcont6{
  display: flex !important;
  
}
.ftext1{
    font-size: 12px;
    margin-top: 2%;
    font-weight: 300;
    font-family: 'Verdana', sans-serif;
    line-height: 20px;
}

.ftext1-1{
    margin-top: 5%;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Verdana', sans-serif;
    line-height: 20px
   }

.ftext1-2{
    font-weight: 400;
    font-size: 12px;
    margin-top: 0%;
}

.ftext2{
    font-weight: 600;
    font-size: 14px;
}

.ftext3{
    font-size: 11px;
    font-weight: 300;   
    margin-top: 3%; 
}



.vl-footer {
    border-left: 3px solid #68D1D1;
    height: 130px;
    margin-top: 0%;
    margin-left: 2%;
}

.vl-cv-footer {
    border-bottom: 3px solid #68D1D1;
      height: 50px;
      margin-left: -10%;
      margin-right: -2%;
      margin-bottom: 3%;
    }

.imgfooter{
    width: 100%;
}

.imgfooterapp{
    width: 50%;
}
.w-25f{
    width: 2%;
    margin-left: 5%;
}

.fcontt1{
    display: flex;
    width: auto;

}

.fcontt2{
    display: flex;
    width: auto;
  
}


@media only screen
and (min-width: 0px) and (max-width: 750px){
    .footer-contendor {
        margin-top: 3%;
        display: block;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .fcont0 {width: 30%;
        margin-left: 0%;
        margin-top: 6%;
    }

    .fcont1{    
        width: auto;
        margin-left: 29%;
        margin-top: -24%;
    }
    .fcont2 {
        width: auto;
    }
    .ftext1 , .ftext1-1 {
        font-size: 13px;
    }
    .fcont3 {    width: auto;
        margin-top: 0%;
        margin-left: 0%;

    }

    .fcont4{
        margin-top:0%
    }
    .vl-footer{
        display: none;
    }

    .fcont5{
        display: block;
    }

    .ftext3 {
        font-size: 11px;
        font-weight: 300;
        margin-top: 3%;
        display: block;
        margin-left: 0%;
    }

    .w-25f {
        width: 2%;
        margin-left: 2%;
    }

    .facebook{
        margin-left: 3%;
    }
}

@media only screen
and (min-width: 751px) and (max-width: 1000px){

}

@media only 
  screen and (min-width: 1500px) and (max-width:2500px){
    .ftext1 {
        font-size: 30px;
        margin-top: 2%;
        font-weight: 300;
        font-family: 'Verdana', sans-serif;
        line-height: 40px;
    }
    .ftext1-1 {
        margin-top: 5%;
        font-size: 26px;
        font-weight: 300;
        font-family: 'Verdana', sans-serif;
        line-height: 33px;
    }

    .fcont0 {
        margin-left: 11%;
        width: 13%;
    }

    .ftext2 {
        font-weight: 600;
        font-size: 30px;
    }
    .ftext1-2 {
        font-weight: 400;
        font-size: 26px;
        margin-top: 5%;
    }
    .fcontt1 {
        width: 15%;
    }
    .ftext3 {
        font-size: 21px;
        font-weight: 300;
        margin-top: 6%;
    }
    .imgfooterapp {
        width: 80%;
    }
    .vl-footer {
        border-left: 3px solid #68D1D1;
        height: 246px;
        margin-top: 0%;
        margin-left: 1%;
    }
    .fcont2 {
        width: 13%;
    }

    .fcont3 {
        width: 15%;
    }


  }