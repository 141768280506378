.b-contendor{
    display: flex;
    background-color: #ffffff;
    padding: 0% 1% 0% 8%;
}

.b-txt1{
    margin: auto 0;
    color: #8039B0;
    font-size: 19px;
    text-align: center;
    width: 21%;
}

.imgRelojHome{
    width: 5%;
    margin-left: 0.5%;
    margin-right: 0.5%;
 
}

.interbanco{
    margin-top: 1%;
    width: 33%;
}
.bcpbanco{
    margin-top: 1%;
    width: 18%;
    margin-right: 5%;
    margin-left: -2%;
}

.sbs{
    width: 80%;
    margin-left: 25%;
}


.bancos{

    display: flex;
}

.b-txt2{
    color: #585a59;
    font-size: 12px;
    margin-top: 15%;
}

.b-txt2-1{
    color: #585a59;
    font-size: 12px;
    margin-top: 12%;
    margin-left: 55%;
}



.vl {
    border-left: 2px solid #666666;
    height: 85px;
    margin-left: 2.7%;
    margin-right: 4.2%;
    margin-top: 3%;
  }

  .vl-mayor {
    border-left: 3px solid #8039B0;
    height: 170px;
    margin-top:0%;
    margin-left: -15%;
  }


  .ban-ct1{
  
        margin-top: -3.5%;
        margin-left: 3%;
        /* width: 50%; */


  }

  .ban-ct3{
    width: 30%;
  }


  @media only screen
  and (min-width: 0px) and (max-width: 750px){

  
    .b-contendor {
        display: block;
    }
    
    .b-txt1{
        font-size: 20px;
        text-align: center;
        width: 100%;
    }

    .b-txt1 {
        margin: auto 0;
        color: #8039B0;
        margin-top: 2%;

    }
    
    .imgRelojHome{
        display: none;
    }
    
    .b-txt2 {
        font-size: 12px;
        margin-top: 1%;
        text-align: center;
        margin-top: 2%;
        margin-bottom: 1%;
    }
    .vl,.vl-mayor{
        display: none;
    }
    
    .ban-ct3 {
        width: 100%;
    }
    .bcpbanco {
        margin-top: 1%;
        width: 18%;
        margin-right: 10%;
        margin-left: 22%;
    }
    .b-txt2-1 {
        font-size: 12px;
        margin-top: 5%;
        margin-left: 4%;
        text-align: center;
        /* margin-bottom: 10%; */
    
    }
    .sbs {
        width: 50%;
        /* margin-left: 25%; */
        /* text-align: center; */
        margin-left: 25%;
        margin-bottom:2%
    }
 
  }

@media only screen
  and (min-width: 751px) and (max-width: 1000px){
    
  }

 @media only 
	screen and (min-width: 1500px) and (max-width:2500px){
        .b-txt1 {
            margin: auto 0;
            color: #8039B0;
            font-size: 30px;
            text-align: center;
            width: 18%;
        }
        
.b-txt2 {
    color: #585a59;
    font-size: 16px;
    margin-top: 26%;
}
.ban-ct1 {
    margin-top: -3.5%;
    margin-left: 6.5%;
    /* width: 50%; */
}
.vl-mayor {
    border-left: 3px solid #8039B0;
    height: 240px;
    margin-top: 0%;
    margin-left: 0%;}

    .b-txt2-1 {
        color: #585a59;
        font-size: 24px;
        margin-top: 7%;
        margin-left: 59%;
        margin-bottom: -2%;
    }    
    .sbs {
        width: 80%;
        margin-left: 25%;
        margin-bottom: 4%;
    }
    .bcpbanco {
        margin-top: 2%;
        width: 25%;
        margin-right: 5%;
        margin-left: -2%;
    }
    .interbanco {
        margin-top: 2%;
        width: 40%;
    }
    }