

.conthistory{
 flex-wrap: nowrap;;
    width: 78%;

    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;

    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}


.conthistoryt{
    width: 90%;
    margin: 0 auto;
    padding: 2%;
}

@media only screen
and (min-width: 0px) and (max-width: 750px){
    .conthistoryt {
        width: auto;
        margin: 0;
        padding: 0%;
    }
    
}

@media only screen
and (min-width: 751px) and (max-width: 1000px){}

@media only 
  screen and (min-width: 1500px) and (max-width:1920px){}

