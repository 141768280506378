body,html{
    height:100%;
}

#root {
    height:100%;
}

.navbar {
    height:auto;
    flex-shrink:1;
}
#wrapper{
    min-height:100%;
    display:flex;
    flex-direction:column;
}
#page{
    /* padding-top: 1rem; */
    /* padding-bottom: 1rem; */
    background-color: #f2f3f8;
    /* flex-grow: 5; */
}

@media (min-width: 768px) {
    /* #page{
        padding: 2rem;
    } */
}

#footer{
    position:relative;
    flex-grow:0.03;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}